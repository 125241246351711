import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'

class TechPage extends React.Component {
  render() {
    const exp = this.props.data.mdx
    const meta = exp.frontmatter
    return (
      <Layout>
        <div className="flex min-h-screen flex-col">
          <header>
            <Link to="/" className="no-underline text-black">
              <h1 className="text-right leading-loose">&lang; Rainer Dreyer</h1>
            </Link>
          </header>

          <div className="experience-content flex-auto">
            <MDXRenderer>{exp.code.body}</MDXRenderer>
          </div>

          <footer className="py-4">
            {meta.tech &&
              meta.tech.map(name => (
                <span key={name}>
                  <span className="tech">{name}</span>{' '}
                </span>
              ))}
          </footer>
        </div>
      </Layout>
    )
  }
}
export default TechPage

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      code {
        body
      }
      frontmatter {
        type
        dates {
          start
          end
          event
        }
        org
        detail
        tech
        role
      }
    }
  }
`
